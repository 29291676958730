$black: #565656;
$dark: #333333;
$white: #ffffff;
$red: #ff4444;
$green: #c0db09;
$blue: #56bbe8;
$orange: #f78100;
$purple: #8a6bff;
$dark-purple: #0a3c6f;
$dark-blue-gray: #33373c;

$button--green: #40b979;
$border-main: #d9dde0;

$dark-gray: #3a3c40;
$gray: #757b82;
$light-gray: #939596;
$ultralight-gray: #f5f6f7;

$rcBrowserBg: $white;
$rcBrowserBodyText: #333333;
$rcBrowserDarkBg: $white;
$rcBrowserSubText: #666666;



$icon--best: #6f95ff;
$icon--active: $light-gray;
$icon--upcoming: #ff6f90;
$icon--resolved: $green;
