@charset "UTF-8";

@import "util/normalize";
@import "util/easing";
@import "util/color";
@import "util/mixin";
@import "util/typography";
@import "util/keyframes";
@import "util/perfect-scrollbar";

html {
    position: relative;
    height: 100%;
}

body {
    min-height: 100%;
    margin: 0;
    font-family: $font-family-sans, sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 16px;
    font-weight: 400;
    background-color: $white;
    color: $black;
    font-smoothing: antialiased;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
    -webkit-tap-highlight-color:rgba(0,0,0,0);
    background-color: #f0f4f8;
}

html,body {
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
}

body.page-qa {
    background-color: $white;
}

img {
    max-width: 100%;
}

a {
    color: inherit;
    cursor: pointer;
}

a:hover {
    color: inherit;
    text-decoration: none;
}

.clearfix:after {
	content: "";
	clear: both;
  display: block;
}

.overlay {
    position: absolute;
    z-index: 200;
    background: rgba(255,255,255,0.8);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: none;
}

body.error .overlay {
    display: block;
}

input, button, textarea, select {
    margin: 0;
    padding: 0;
    border-radius: 0;
    appearance: none;
    border: none;
    border-radius: 0;
    outline: none;
    background: none;
    box-sizing: border-box;
    font-family: inherit;
    &::-webkit-search-decoration {
        display: none;
    }
    &::focus {
        outline-offset: -2px;
    }
}

div.top-content-controls select {
    appearance: menulist-button;
    -webkit-appearance: menulist-button;
    -moz-appearance: menulist-button;
}

.filter-edit-container select {
    appearance: menulist-button;
    -webkit-appearance: menulist-button;
    -moz-appearance: menulist-button;
}

.hidden {
    display: none;
}

.page-bg {
    position: absolute;
    z-index: -3;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    height: 390px;
}

a.upper-button:hover {
    text-decoration:underline !important;
}

.page-qa #primary-container {
    height: 64%;
    background-color: transparent;
}

// Site Header
#site-header {
    width: 100%;
    display: inline-block;
    height: 250px;
    text-align: right;
}


#site-header a,
#site-header div#your-lang-button {
    margin-left: 20px;
    text-decoration: none;
    padding: 20px;
    font-weight: bold;
    display: inline-block;
    margin-top: 20px;
    margin-bottom: 20px;
    position: relative;
}

#token-selection {
    font-weight: normal;
    display: inline-block;
    color: #939596;
    float:right;
}

#token-selection.uninitialized {
    visibility: hidden
}

#contract-selection {
    font-weight: normal;
    display: inline-block;
    color: #939596;
}

#contract-selection.uninitialized {
    visibility: hidden
}



hr.upper-divider {
    height: 3px;
    border: none;
    color: #27b4ee;
    background: #27b4ee url(../image/line_style.png) center right no-repeat;
    background-size: contain;
}

h1.logo {
    overflow: hidden;
    display: block !important;
    text-indent: -900000px;
    background: url(../image/realitio_header_logo.png) top left no-repeat;
    background-size: contain;
    position: absolute;
    left: -100px;
    width: 317px;
    height: 105px;
    margin-left: 0;
    margin-top: 0;
    text-align: left;
    top: 0px;
}

div.network-status-container {
    text-align: left;
    margin-top: -50px;
    margin-left: 71px;
    height:20px;
    visibility:hidden;
}
div.network-status-container.initialized {
    visibility:visible;
}



span.network-status {
    text-indent: 61px !important;
    display: block;
    bottom: 0px;
    font-size: 12px;
    text-align: left;
    width: 100%;
    font-weight: normal;
    color: #939596;
}   

.via-node-only { display: none; }
.via-graph-only { display: none; }
body.via-node .via-node-only { display: inline; }
body.via-graph .via-graph-only { display: inline; }

body.via-node graph-node-switch-link {display:inline; }
body.via-graph graph-node-switch-link {display:inline; }

.graph-node-switch-link {
    width: 240px;
    height: 60px;
    line-height: 60px;
    margin: 15px 0 0;
    padding: 9px;
    border-radius: 3px;
    font-size: 12px;
    // background-color: $blue;
    @include button_blue;
    text-align: center;
    cursor: pointer;
}

span.network-status.test-network {
    display: none;
}

#site-header .logo a {
    display: block;
    margin: 0;
}

#site-title {
    margin: 0 auto 0 0;
    font-size: 23px;
    font-weight: 500;
    color: $white;
    letter-spacing: -0.05em;
}

span.network-status {
    text-indent: 61px !important;
    display: block;
    bottom: 0px;
    font-size: 12px;
    text-align: left;
    width: 100%;
    font-weight: normal;
    color: #939596;
    display: none;
}   

.page-qa #site-title {
    color: inherit;
}

#site-title > a {
    text-decoration: none;
}

#site-logo {
    width: 42px;
}

#site-header-inner > a {
    margin-left: 42px;
    color: $white;
    text-decoration: none;
    font-size: inherit;
    font-weight: 500;
}

.page-qa #site-header-inner > a {
    color: $black;
}

.search-button {
    display: block;
    width: 18px;
    height: 18px;
    margin-left: 48px;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url(../image/icon-search--light.svg);
}

.page-qa .search-button {
    background-image: url(../image/icon-search--dark.svg);
}

.page-qa a#your-qa-button {
    background-image: url(../image/icon-identity--dark.svg);
}


body #push-icon {
    display:none;
    font-family: 'Material Icons';
    width: 20px;
    height: 20px;
    right: -5px;
    position: absolute;
    top: 16px;
    color: $orange;
    font-size: 20px;
}

body.pushing #push-icon {
    display: block;
}

#language-icon {
    font-family: 'Material Icons';
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    left: 20px;
    top: 18px;
    color: #565656;
    font-size: 20px;
    font-weight: normal;
    color: $black;
}

select#language {
    color: $black;
    font-weight: bold;
    position: relative;
    z-index: 2;
    width: 50px;
    text-indent: 24px;
}

body.pushing .page-qa #push-icon {
    border: solid 2px $white;
}

// Site Description
#site-introduction {

}

#page-title,
.site-slogan {
    font-size: 48px;
    color: $white;
    font-weight: 300;
    margin: 0;
}

.page-qa .site-slogan,
.page-qa #page-title {
    color: $black;
}

#site-slogan-normal > span,
#page-title > span {
    font-weight: 500;
}

#site-description {
    width: 45em;
    margin: 12px auto;
    font-size: inherit;
    font-weight: 400;
    line-height: 1.6;
    color: rgba(255,255,255,.8);
}

.page-qa #site-description {
    color: $gray;
}

#site-introduction__buttons {
    width: 100%;
    clear: both;
    margin-top: 50px;
    text-align: center;
}

.button--bounce {
    transition: all 100ms;
}

.button--bounce:active {
    transform: scale(0.98);
}

#post-a-question-button {
    width: 240px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    color: #ffffff;
    font-size: inherit;
    font-weight: 500;
    border-radius: 8px;
    margin: 0 10px 0;
    text-decoration: none;
    box-shadow: rgba(23, 43, 99, 0.26) 2px 2px 5px;
    transition: all 100ms;
    padding: 2px !important;
    background: #27b4ee; /* Old browsers */
    background: -moz-linear-gradient(top, #27b4ee 0%, #0aaaec 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #27b4ee 0%,#0aaaec 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #27b4ee 0%,#0aaaec 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#27b4ee', endColorstr='#0aaaec',GradientType=0 ); /* IE6-9 */
}

#post-a-question-button:active {
    box-shadow: rgba(23,43,99,0.36) 0 7px 26px;
    transform: scale(0.98);
}

#browse-question-button {
    width: 179px;
    height: 54px;
    line-height: 52px;
    box-sizing: border-box;
    margin: 0 10px 0;
    text-align: center;
    color: $ultralight-gray;
    border: solid 1px rgba(255,255,255,.2);
    background-color: rgba(255,255,255,.15);
    font-size: inherit;
    font-weight: 500;
    border-radius: 3px;
    text-decoration: none;
    transition: all 300ms;
}

#browse-question-button:active {
    transform: scale(0.98);
}

// scroll to discover
#scrolltodiscover-container {
    position: absolute;
    bottom: 8%;
    left: 5%;
    color: $light-gray;
    font-size: inherit;
    font-weight: 500;
    overflow: hidden;
}

#scrolltodiscover {
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate3d(0, 100%, 0);
    transition: all 400ms ease 600ms;
}
.is-page-loaded #scrolltodiscover {
    transform: translate3d(0, 0%, 0);
}

#questions-container div.questions {
    min-height:600px;
}

.arrow-down-icon-container {
    width: 14px;
    height: 14px;
    margin-right: 9px;
    overflow: hidden;
}

.arrow-down-icon {
    width: 14px;
    height: 14px;
    animation: arrow-up-down 1.6s ease infinite;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url(../image/icon-arrowdown.svg);
}

#cover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-image: url('../image/cover-image.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    opacity: .15;
}

// main
#main {
    position: relative;
    z-index: 3;
    margin: 0 auto;
    background-color: transparent;
    max-width: 1100px;
    min-width: 980px;
}

// Filter
#filter {
    width: 100%;
    z-index: 5;
}

#filter.is-sticky {
    position: fixed;
    bottom: auto!important;
    top: 0;
    right: 5%;
    opacity: 1;
    visibility: visible;
}

#filter.is-no-sticky {
    position: absolute;
    top: auto!important;
    bottom: 0;
    right: 5%;
}

#filter-header {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $dark-gray;
    height: 57px;
    position: relative;
    z-index: 3;
    color: $white;
    cursor: pointer;
}

#filter:hover #filter-header {
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.4);
}

#filter-header::after {
    display: block;
    content: "";
    position: absolute;
    bottom: 50%;
    right: 15px;
    transform: translate3d(0, 50%, 0);
    width: 22px;
    height: 22px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url(../image/icon-dropdown.svg);
    opacity: .54;
}

#filter-header > span:first-child {
    margin-right: 9px;
    color: rgba(255,255,255,.6);
    font-size: 13px;
}

#filter-list {
    margin: 0;
    padding: 0;
    list-style: none;
}


// Questions
#questions-container {
    padding: 40px;
    box-shadow: 1px 1px 10px rgba(0,0,0,.2);
    margin: 20px auto 80px;
    background-color: #fff;
}

.nav-bar {
    background-color: $blue;
    margin-left: -40px;
    margin-right: -40px;
    margin-top: -55px;
    margin-bottom: 40px;
}

#filter-list li {
    display: inline-block;
    margin-right: 10px;
}

#filter-list li a {
    color: $blue;
    font-size: 15px;
}

div.category-list {
    display:none;
}
.category-list {
    margin-top: -20px;
    margin-bottom: 0;
    border-bottom: solid 1px darken($ultralight-gray, 10%);
    padding: 0 0 20px;
}

#filter-list li a.selected {
    font-weight: bold;
}

.main-nav a {
    display: block;
    margin: 10px 0;
    padding: 20px;
    border-radius: 6px;
    font-weight: bold;
    color: $white;
    opacity: 60%;
}

.main-nav a.selected {
    color: $blue;
    background: rgba(255,255,255,.8);
    opacity: 100%;
}

.main-nav a.activated{
    opacity: 100%;
}

body.initial-loading-done .main-nav a {
    opacity: 100%;
}

ul.main-nav {
    margin: 0 -20px 0 0;
    padding: 0px 0 0 10px;
}

.nav-bar li {
    display: inline-block;
    list-style-type: none;
    width: 32%;
    text-align: center;
}

.questions {
    margin-top: -25px;
    width: 100%;
    position: relative;
}

.page-qa .questions {
    transform: translate3d(0, 0%, 0);
}

.js-box-shadow {
    box-shadow: rgba(29,29,31,0.13) 0 10px 60px;
}

.questions:first-child {
    transition: all 600ms $ease-in-out-back 200ms;
}
.is-page-loaded .questions:first-child,
.page-qa .questions:first-child {
    transform: translate3d(0, -8%, 0);
}

.questions:nth-child(2) {
    transition: all 600ms $ease-in-out-back;
}
.is-page-loaded .questions:nth-child(2),
.page-qa .questions:nth-child(2) {
    transform: translate3d(0, -16%, 0);
}

.questions:nth-child(3) {
    transition: all 600ms $ease-in-out-back 100ms;
}
.is-page-loaded .questions:nth-child(3),
.page-qa .questions:nth-child(3) {
    transform: translate3d(0, -12%, 0);
}

.questions:last-child {
    transition: all 600ms $ease-in-out-back 300ms;
}
.is-page-loaded .questions:last-child,
.page-qa .questions:last-child {
    transform: translate3d(0, -8%, 0);
}

.no-questions {
    text-align: center;
    padding: 20px;
    background: #f6f6f6;
    border-radius: 4px;
    color: #999;
}
.no-questions-category {
    text-align: center;
    padding: 20px;
    background: #f6f6f6;
    border-radius: 4px;
    color: #999;
}

.questions-header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 42px 0 47px;
    font-size: 18px;
    font-weight: 500;
}

.questions-header::after {
    display: block;
    content: "";
    width: 88%;
    height: 5px;
    border-radius: 2.5px;
    background-color: darken($ultralight-gray, 10%);
    position: absolute;
    bottom: 0;
    right: 50%;
    transform: translate3d(50%, 0, 0);
}

.questions-header__icon {
    position: relative;
    width: 16px;
    height: 16px;
    margin-top: 1px;
    margin-right: 9px;
}

.questions-header__icon > span {
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    bottom: 50%;
    right: 50%;
    transform: translate3d(50%, 50%, 0);
}

.questions-header__icon::after {
    display: block;
    content: "";
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: solid 1px;
    box-sizing: border-box;
    background-color: transparent;
    top: 0;
    left: 0;
}

.questions-header__icon--best > span {
    background-color: $icon--best;
    box-shadow: 0 0 14px $icon--best;
}
.questions-header__icon--best::after {
    border-color: $icon--best;
}

.questions-header__icon--active > span {
    background-color: $icon--active;
    box-shadow: 0 0 14px $icon--active;
}
.questions-header__icon--active::after {
    border-color: $icon--active;
}

.questions-header__icon--upcoming > span {
    background-color: $icon--upcoming;
    box-shadow: 0 0 14px $icon--upcoming;
}
.questions-header__icon--upcoming::after {
    border-color: $icon--upcoming;
}

.questions-header__icon--resolved > span {
    background-color: $icon--resolved;
    box-shadow: 0 0 14px $icon--resolved;
}
.questions-header__icon--resolved::after {
    border-color: $icon--resolved;
}

.questions__item {
    width: 100%;
    margin: 0 auto;
    padding: 24px 0 33px;
    border-bottom: solid 1px darken($ultralight-gray, 10%);
}

.questions__item:last-child {
    border-bottom: none;
}

span.question-title {
    overflow: hidden;
    position: relative;
    line-height: 1.2em;
    max-height: 2.4em;
    margin-right: 0em;
    padding-right: 1em;
    display: block;
}

span.question-title:before {
  content: '...';
  position: absolute;
  right: 0;
  bottom: 0;
}

span.question-title:after {
  content: '';
  position: absolute;
  right: 0;
  width: 1em;
  height: 1em;
  margin-top: 0.2em;
  background: white;
}

.questions__item-inner {
    padding: 0 27px 0 0;
    position: relative;
}

.rcbrowser-main-header-title.question-title,
a.questions__item__title {
    display: block;
    text-decoration: none;
    margin-bottom: 9px;
    line-height: 1.6;
    font-size: 1.6em;
    font-family: $font-family-serif;
    font-weight: normal;
    color: $gray;
}

.long-title .rcbrowser-main-header-title.question-title {
    line-height: 1.4;
    font-size: 1.3em;
}

.rcbrowser-main-header-title.question-title {
    margin: 10px 0px 0px 0px;
}
a.questions__item__title:hover {
    color: $blue;
    text-decoration: underline;
}

.questions__item__answer{
    padding: 9px 0px 10px 20px;
    position: absolute;
    top: 60px;
    right: 0;
    overflow: hidden;
    float: right;
    width: 230px;
    margin-top: -52px;
    text-align: left;
    height: 68px;
    font-family: $font-family-serif;
    color: $gray;
    border-left: 1px dashed #ccc;
    font-size: 1.6em;
}
.questions__item__answer_footer {
    padding: 9px 0px 10px 20px;
    position: absolute;
    top: 76px;
    right: 0;
    overflow: hidden;
    float: right;
    width: 230px;
    text-align: left;
    font-family: $font-family-sans;
    color: $light-gray;
    font-size: 13px;
}

.questions__item__answer_footer:before {
    margin-right: 20px;
}

.questions__item.no-answers .bond-value-container {
    display:none;
}

.questions__item .answer-mini-link-container {
    float:right;
}

.questions__item .answer-mini-link-container a {
    display:none;
    font-style: italic;
}

.questions__item.no-answers {
    a.answer-link { display:inline; }
}

.questions__item.has-answers {
    a.correct-link { display:inline; }
}

#questions-resolved .questions__item .answer-mini-link-container {
    display:none;
}

.questions__item.arbitration-pending .answer-mini-link-container {
    display:none;
}


.questions__item .opening-time-label {
    display:none;
    float:right;
}

.questions__item.not-yet-open .answer-mini-link-container {
    display:none;
}

.questions__item.not-yet-open .opening-time-label {
    display:inline;
}

.questions__item.has-answer a.questions__item__title,
.questions__item.no-answers a.questions__item__title {
    margin-right: 260px;
    height:2.4em;
}

.questions__item__footer {
    font-size: 13px;
    color: $light-gray;
    margin-right: 270px;
}

.loadmore-button {
    width: 240px;
    height: 60px;
    line-height: 60px;
    margin: 40px auto 10px;
    font-weight: 500;
    color: $gray;
    text-align: center;
    background-color: $white;
    box-shadow: 0 0 8px rgba(0,0,0,.2);
    border-radius: 6px;
    cursor: pointer;
    display: none;
}

.loadmore-text {
    display: block;
}

$loder-width: 16px;
$loder-border-size: 3px;
.loader {
    display: none;
    width: $loder-width;
    height: $loder-width;
    border-radius: 50%;
    background-color: transparent;
    border-top: $loder-border-size solid darken($ultralight-gray, 18%);
    border-right: $loder-border-size solid darken($ultralight-gray, 18%);
    border-bottom: $loder-border-size solid $blue;
    border-left: $loder-border-size solid $blue;
    animation: loading .6s infinite linear;
}

.loadmore-button.is-loading .loadmore-text {
    display: none;
}

.loadmore-button.is-loading .loader {
    display: block;
}

// search
#search {
    padding: 50px 0;
}

#search-header {
    margin: 0 0 24px;
    text-align: center;
    font-weight: 400;
    font-size: 27px;
}

#search-logo {
    width: 45px;
    margin: 0 auto 9px;
}

#search-header > span {
    display: block;
    margin: 0 auto;
    color: $black;
}

#search-form-container {
    position: relative;
    color: inherit;
    width: 500px;
    margin: 0 auto;
    padding: 24px 0 78px;
}

#search-form {
    position: relative;
    border-bottom: solid 1px $black;
}

#search-input {
    width: 100%;
    font-size: 18px;
    padding: 12px 0 12px 10px;
    color: $black;
    font-family: inherit;
}

/* Google Chrome, Safari, Opera 15+, Android, iOS */
#search-input::-webkit-input-placeholder {
    color: $black;
    font-family: inherit;
}

/* Firefox 18- */
#search-input:-moz-placeholder {
    color: $black;
    font-family: inherit;
}

/* Firefox 19+ */
#search-input::-moz-placeholder {
    color: $black;
    font-family: inherit;
}

/* IE 10+ */
#search-input:-ms-input-placeholder {
    color: $black;
    font-family: inherit;
}

#search-input:placeholder-shown {
    color: $black;
    font-family: inherit;
}

#search-submit {
    width: 18px;
    height: 18px;
    background-image: url(../image/icon-search--dark.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    bottom: 50%;
    right: 10px;
    transform: translate3d(0, 50%, 0);
    cursor: pointer;
}

#result-uint,#result-int{
    display: block;
    padding: 12px 0 0;
    position: absolute;
    bottom: 0;
    right: 50%;
    transform: translate3d(50%, 100%, 0);
}

// footer
#site-footer {
    width: 100%;
    color: $black;
    position: relative;
    z-index: 1;
    background-color: #1b2942;
    padding: 30px 0 80px;
}

#site-footer h1.logo {
    background: url(../image/realitio_logo_white.png) top left no-repeat;
    background-size: contain;
    position: relative;
    left: 79px;
    width: 230px;
    height: 40px;
    margin-left: 0;
    margin-top: 13px;
}

// footer nav
#site-footer-nav {
    margin: 0 auto;
    padding: 33px 0;
}

#site-footer-nav > ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

#site-footer-nav > ul > li {
    display: inline-block;
    margin-right: 30px;
    font-size: 15px;
}

#site-footer-nav > ul > li:last-child {
    margin-right: 0;
}

li.site-footer-nav__divider {
    color: rgba(0, 0, 0, .2)!important;
}

#site-footer-nav > ul > li > a {
    color: inherit;
    text-decoration: none;
}

// site footer nav --secondary
#site-footer-nav--secondary {
    max-width: 1024px;
    min-width: 980px;
    margin: 0 auto;
    padding: 42px 0;
}

#site-footer-nav--secondary > ul {
    margin: 0 0 0 96px;
    padding: 0;
    list-style: none;
}

#site-footer-nav--secondary > ul:first-child {
    margin: 0 auto 0 0;
}

#site-footer-nav--secondary > ul > span {
    display: block;
    color: $gray;
    font-size: 13px;
    margin-bottom: 24px;
}

#site-footer-nav--secondary > ul:first-child > span {
    margin-bottom: 9px;
}

#site-footer-nav--secondary > ul > li {
    margin-bottom: 18px;
    font-size: 13px;
}

#site-footer-nav--secondary > ul:first-child > li {
    font-size: 18px;
}

#site-footer-nav--secondary > ul > li > a {
    text-decoration: none;
}

#copyright > a {
    display: block;
    font-size: 18px;
    color: $black;
    text-decoration: none;
}

a#previous-version {
    position: absolute;
    bottom: 6px;
    left: 6px;
    font-size:12px;
    color:$white;
}

a#backtotop {
    position: absolute;
    bottom: 6px;
    right: 6px;
    width: 52px;
    height: 52px;
    background-color: $dark-gray;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

a#backtotop:hover {
    background-color: $blue;
}

#backtotop > span {
    display: block;
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url(../image/icon-arrowup.svg);
}

footer .cols {
    color: #fff;
    float: left;
    width: 260px;
}

footer .cols:first-child {
    width: 460px;
}

footer .cols h3 {
    margin-top: 0;
}

footer .cols ul {
    list-style: none;
    margin-left: 0;
    padding-left:0;
    line-height: 30px;
}

.cols a {
    color: #fff;
    text-decoration: none;
}

// RC Browser
.rcbrowser {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    background-color: $rcBrowserBg;
    border-radius: 3px;
    box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.3);
    color: $gray;
    width: 800px;
}

.rcbrowser a {
    text-decoration: none;
    color: black;
}

.rcbrowser a:hover {
    color: $rcBrowserBodyText;
    text-decoration: underline;
}

.rcbrowser-inner {
    height: 100%;
    overflow-y: scroll;
    position: relative;
    margin: 0;
}

.rcbrowser-inner .cols-2 {
    float: left;
    width: 50%;
}

.rcbrowser.is-open {
    opacity: 1;
    visibility: visible;
}

.rcbrowser--postaquestion,
.rcbrowser--postaquestion .rcbrowser-header {
    width: 800px;
}

.rcbrowser--qa-detail,
.rcbrowser--qa-detail .rcbrowser-header {
    width: 800px;
}

.rcbrowser--your-qa,
.rcbrowser--your-qa .rcbrowser-header {
    width: 800px;
}

.rcbrowser--qa-detail--resolved .current-answer-container {
    margin: 0 0 15px 0;
}

.rcbrowser--help-center, .rcbrowser--help-center .rcbrowser-header {
    width: 800px;
}
.rcbrowser--help-center {
    height: 100%;
}

.help-explanation > p {
    line-height: 1.7;
    font-size: 15px;
    color: $rcBrowserBodyText;
}

.chain-list-explanation {
    line-height: 1.7;
    font-size: 15px;
    color: $rcBrowserBodyText;
}

div.graph-node-switch {
    padding:12px;
    margin-top:14px;
    border-radius: 6px;
    border: 1px solid $gray;
}

// rcBrowser header
.rcbrowser-header {
    z-index: 2;
    height: 55px;
    width: 100%;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    background-color: $blue;
    text-align: center;
    line-height: 55px;
    position: fixed;
}

.rcbrowser-loader-container {
    position: absolute;
    bottom: 50%;
    right: 10px;
    transform: translate3d(0, 50%, 0);
}

.rcbrowser-loader-container > .loader {
    width: 12px;
    height: 12px;
    border-top: $loder-border-size solid $rcBrowserBg;
    border-right: $loder-border-size solid $rcBrowserBg;
}

.rcbrowser.is-loading .loader {
    display: block;
}

.rcbrowser-control {
    position: absolute;
    left: 16px;
    top: 17px;
}

.rcbrowser-control > span:first-child {
    display: block;
}

.rcbrowser-header div.rcbrowser__close-button {
    height: 42px;
    width: 42px;
    cursor: pointer;
}

div.rcbrowser__close-button.close-question-window {
    @include button_white;
    display: inline-block;
    margin: 0;
}

div.rcbrowser__close-icon {
    margin: 0;
    background-image: url(../image/icon-close.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 8px 8px;
    display: inline-block;
    width:8px;
    height:8px;
    float: left;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: $white;
    color: $blue;
    margin-right: 5px;
}

.rcbrowser__buttons {
    text-align: center;
    padding-top: 15px;
}

// rcBrowser main
.rcbrowser-main {
    margin: 0 0 15px;
    padding: 60px 30px 0;
}

// rcBrowser main header
.rcbrowser-main-header {
    text-align: left;
    border-bottom: solid 2px lighten($rcBrowserBg, 10%);
    padding: 0;
}

.rcbrowser-warning-header {
    padding-top: 0px;
    margin-left: -15px;
    margin-right: -15px;
}

.rcbrowser-main-header-title {
    font-size: 18px;
    font-weight: 600;
    margin: 0 0 9px;
    color: $white;
}

.rcbrowser-main-header-subtitle {
    font-size: 13px;
    font-weight: 400;
    color: $rcBrowserSubText;
    margin: 15px 0 0;
}

.answer-claiming-container {
    float:right;
    font-size: 13px;
    font-weight: 400;
    color: $rcBrowserSubText;
    display:none;
}

.rcbrowser-main-header-subtitle > span {
    color: $gray;
}

.answer-deadline-container {
    margin: 0;
    padding: 6px 0;
    border-radius: 2px;
    font-size: 12px;
    background-color: $rcBrowserDarkBg;
    color: $gray !important;
}

// rcBrowser--qa-detail main header
.rcbrowser--qa-detail .rcbrowser-main-header {
    text-align: left;
    padding: 18px 0 6px;
    border-bottom: 1px solid $border-main;
    padding-bottom: 1em;
    margin-bottom: 1em;
    position: relative;
}

.rcbrowser-main-header-category-and-date {
    color: $rcBrowserSubText;
    font-size: 12px;
    font-style: italic;
}

.rcbrowser--qa-detail .rcbrowser-main-header-subtitle {
    font-size: 12px;
    font-weight: 400;
    color: $gray;
}

.rcbrowser--qa-detail .rcbrowser-main-header-subtitle > span {
    margin: 0 9px 0 0;
}

.add-reward-button {
    background-color: $blue;
    border-radius: 2px;
    font-size: 12px;
    padding: 2px;
    cursor: pointer;
    color: $white !important;
}

.add-reward-container {
    overflow: hidden;
    display: none;
}

.add-reward {
    margin: 15px 0;
    background-color: $rcBrowserDarkBg;
    border-radius: 3px;
    padding: 6px 9px;
}

.rcbrowser-submit.rcbrowser-submit--add-reward {
    @include button_blue;
}

.add-reward__close-button {
    float: right;
    font-size: 13px;
    cursor: pointer;
    margin-top: -6px;
}

.add-reward__close-button::before {
    display: inline-block;
    content: "";
    width: 9px;
    height: 9px;
    margin-right: 2px;
    background-image: url(../image/icon-close--light.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 9px 9px;
}


// rcbrowser main body
.rcbrowser-main-body {
    padding: 0;
    min-height: 160px;
}

// arbitration button
.arbitration-button, a.arbitration-button {
    font-size: 12px;
    color: $rcBrowserSubText;
    text-decoration: none;
    display: block;
    float: right;
    margin: 0;
    margin-left:12px;
}

.arbitration-button.unpopulated {
    visibility: hidden;
}

.apply-for-arbitration-container .arbitrator-tos-link {
    font-size: 12px;
    color: $rcBrowserSubText;
    text-decoration: none;
    display: block;
    float: right;
    margin: 0;
    font-style: italic;
}

.apply-for-arbitration-container .arbitrator-tos-link.unpopulated {
    visibility: hidden;
}

// arbitration button
.arbitration-button-foreign-proxy, a.arbitration-button-foreign-proxy {
    font-size: 12px;
    color: $rcBrowserSubText;
    text-decoration: none;
    display: block;
    float: right;
    margin: 0;
}

.arbitration-button-foreign-proxy.unpopulated {
    display: none;
}

// arbitration button
.arbitration-button--applied {
    font-size: 12px;
    color: $rcBrowserSubText;
    text-decoration: none;
    display: block;
    float: right;
    margin: -10px 0 3px;
}

// answer item
.answer-item {
    cursor: pointer;
}

.final-answer-button, .answer-claim-button {
    margin: 15px 0 0;
    padding: 9px;
    border-radius: 3px;
    font-size: 12px;
    // background-color: $blue;
    @include button_blue;
    text-align: center;
    cursor: pointer;
}

#your-question-answer-window {
    min-height:650px;
}
#your-question-answer-window .answer-claim-button {
    float: right;
    margin: -30px 0 0;
}

.bsky-comments-container {
    display:none;
    color: #757b82;
    font-size: 13px;
    min-height:22px;
}
.bsky-comments-container a {
    color: #757b82;
}

.bsky-header-text {
    margin-left:10px;
}

.bsky-comments-container.loaded {
    display:block;
}

.bsky-post-record-text {
    margin-top:4px; 
    font-size:14px;
    max-width:660px;
}

.bsky-comments-header {
    background: url(../image/bluesky.png) top left no-repeat;
    display: flex;
    align-items:center;
    padding-left: 22px;
}

.bsky-comments-preview{
    display: flex;
    align-items:center;
}

.has-replies .bsky-link-container {
    display:none;
}

.bsky-link-container {
    margin-left: 10px;
    display: flex;
    align-items:center;
}

.bsky-comments-body {
    padding-bottom: 10px;
    border-bottom: 1px solid $border-main;
}

.bsky-comment-icons {
    margin-left:10px;
}

.bsky-discuss-link {
    font-size: 13px;
    color: $gray;
    margin: 15px 0;
}
.bsky-discuss-link a {
    color: $gray !important;
}

.bsky-link-container {
    min-height:22px;
}

a.bsky-link-to-bsky-upper {
    color:$gray;
    float:right;
    display:none;
}

.bsky-active .bsky-link-to-bsky-upper {
    display:inline;
}

// current answer
.current-answer-container {
    margin: 15px 0;
    border-bottom: 1px solid $border-main;
}

.current-answer-container.is-open .current-answer-inner {
    opacity: 1;
}

.current-answer-header {
    font-size: 13px;
}

.current-answer-header::before {
    display: inline-block;
    content: "";
    width: 8px;
    height: 8px;
    background-color: $green;
    border-radius: 50%;
    margin-right: 6px;
}

.current-answer-item {
    background-color: $rcBrowserDarkBg;
    margin: 9px 0 15px;
    padding: 15px;
    border-radius: 3px;
    position: relative;
    overflow: hidden;
}



// unconfirmed answer
.unconfirmed-answer-container {
    margin: 15px 0;
    opacity: 0.5;
    display: none;
}

div.rcbrowser--qa-detail.unconfirmed-transaction .unconfirmed-question-header {
    display: block !important;
}

div.rcbrowser--qa-detail .answered-history-container .answered-history-item.expired-commit .current-answer { font-style:italic }
div.rcbrowser--qa-detail .answered-history-container .answered-history-item.unrevealed-commit .current-answer { font-style:italic }

div.rcbrowser--qa-detail .unrevealed-top-answer-container .top-entry-unrevealed-warning {
    color: #ff0000;
}

div.rcbrowser--qa-detail .unrevealed-top-answer-container{
    display:none;
}
div.rcbrowser--qa-detail.top-entry-unrevealed .unrevealed-top-answer-container {
    display:block;
    margin: 15px 2px;
}

.unconfirmed-answer-container.is-open .unconfirmed-answer-inner {
    opacity: 1;
}

.unconfirmed-answer-header {
    font-size: 13px;
}

.unconfirmed-answer-header::before {
    display: inline-block;
    content: "";
    width: 8px;
    height: 8px;
    background-color: $purple;
    border-radius: 50%;
    margin-right: 6px;
}

.unconfirmed-question-header {
    font-size: 13px;
    margin-bottom: 18px;
    display:none;
}

.unconfirmed-question-header::before {
    display: inline-block;
    content: "";
    width: 8px;
    height: 8px;
    background-color: $purple;
    border-radius: 50%;
    margin-right: 6px;
}

.unconfirmed-answer-item {
    background-color: $rcBrowserDarkBg;
    margin: 9px 0 15px;
    padding: 15px;
    border-radius: 3px;
    position: relative;
    overflow: hidden;
}

.unconfirmed-answer-body {
    color: $rcBrowserBodyText;
    font-family: $font-family-serif;
    line-height: 1.2;
    margin-top: -0.2em;
    font-size: 22px;
}

.unconfirmed-answer-body > p {
    margin: 0 0 9px;
}

.unconfirmed-answer-body > p:last-of-type {
    margin: 0;
}

// date
.unconfirmed-answer-item > span {
    color: $rcBrowserSubText;
    font-size: 12px;
}

div.unconfirmed-answer-container span.pending-answer-txid {
    float:right;
}

div.unconfirmed-question-header span.pending-question-txid {
    float:right;
}

.current-answer-body {
    color: $gray;
    line-height: 1.2;
    margin: 0 0 10px;
    font-size: 22px;
    font-family: $font-family-serif;
}

.current-answer-body > p {
    margin: 0 0 9px;
}

.current-answer-body > p:last-of-type {
    margin: 0;
}

// date
.current-answer-item > span {
    color: $rcBrowserSubText;
    font-size: 12px;
}

// answered history
.answered-history-container {
    margin: 15px 0 24px;
    transition: all 400ms;
}

.answered-history-container.is-open .answered-history-inner {
    opacity: 1;
}

.answered-history-header {
    font-size: 13px;
    margin: 9px 0;
    padding: 6px 0;
    border-bottom: solid 1px rgba(255,255,255,.2);
}

.answered-history-item {
    position: relative;
    background-color: $rcBrowserDarkBg;
    margin: 0 0 15px 0;
    padding: 15px;
    border-radius: 3px;
    overflow: hidden;
}

.answered-history-body {
    color: $rcBrowserBodyText;
    line-height: 1.2;
    margin-top: -0.2em;
    font-size: inherit;
}

.answered-history-body > p {
    margin: 0 0 9px;
}

.answered-history-body > p:last-of-type {
    margin: 0;
}

.answer-data {
    overflow: hidden;
    display: none;
}


.answer-data-inner {
    margin: 15px 0 0;
    background-color: $ultralight-gray;
    border-radius: 3px;
    padding: 9px;
}

.answer-data__avatar {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    float: left;
    margin: 0 12px 6px 0;
}

.answer-data__item {
    margin-bottom: 5px;
    font-size: 13px;
    color: $rcBrowserBodyText;
}

.answer-data__item:last-child {
    margin-bottom: 0px;
}

// date
.answered-history-item > span {
    color: $rcBrowserSubText;
    font-size: 12px;
}

// answer form header
.answer-form-header {
    font-size: 13px;
    margin: 0 0 15px;
    padding: 6px 0;
    border-bottom: solid 1px rgba(255, 255, 255, 0.2);
}

.answer-form-container {
    background-color: $rcBrowserDarkBg;
    margin: 15px 0;
    padding: 9px 15px 15px;
    border-radius: 3px;
    border-bottom: 1px solid $border-main;
}

// your balance
.your-balance {
    text-align: right;
    font-size: 13px;
    color: $rcBrowserSubText;
    margin-bottom: -9px;
}

.your-balance > span {
    color: $rcBrowserBodyText;
}

.rcbrowser-textarea {
    display: block;
}

.rcbrowser-input,
.rcbrowser-textarea {
    width: 100%;
    box-shadow: inset 0 0 0 1px $light-gray;
    background-color: $white;
    color: $black;
    border-radius: 3px;
    @include box-shadow;
}

.invalid-selected .rcbrowser-textarea{
    background-color: $rcBrowserDarkBg;
}
.invalid-selected span.input-header{
    visibility: hidden;
}
.multiple-select .invalid-selected .input-entry {
    opacity:0.2;
}

.too-soon-selected .rcbrowser-textarea{
    background-color: $rcBrowserDarkBg;
}
.too-soon-selected span.input-header{
    visibility: hidden;
}
.multiple-select .too-soon-selected .input-entry {
    opacity:0.2;
}

.rcbrowser-input:focus,
.rcbrowser-textarea:focus {
    box-shadow: inset 0 0 0 1px $blue;
}

.is-error .rcbrowser-input,
.is-error .rcbrowser-textarea,
.is-error .rcbrowser-select {
    box-shadow: inset 0 0 0 1px $red;
}

// disable spin button
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
    font-family: inherit;
    font-size: inherit;
}

// rcBrowser textarea
.textarea-container {
    margin: 0;
}

.textarea-container--postaquestion {
    margin-top: 0;
}

.row.clearfix {
    margin: 15px 0;
}

textarea[name="question-body"] {
    height: 100px;
    padding: 12px;
    font-family: $font-family-serif;
    font-size: 20px;
}

textarea[name="question-body"]::-webkit-input-placeholder{
    color: $light-gray;
    font-family: $font-family-serif;
    font-size: 20px;
}
/* Firefox 18- */
textarea[name="question-body"]:-moz-placeholder {
    color: $light-gray;
    font-family: $font-family-serif;
    font-size: 20px;
}
/* Firefox 19+ */
textarea[name="question-body"]::-moz-placeholder{
    color: $light-gray;
    font-family: $font-family-serif;
    font-size: 20px;
}
/* IE 10+ */
textarea[name="question-body"]:-ms-input-placeholder {
    color: $light-gray;
    font-family: $font-family-serif;
    font-size: 20px;
}

textarea[name="question-body"]:placeholder-shown{
    color: $light-gray;
    font-family: $font-family-serif;
    font-size: 20px;
    width: calc(100% - 30px);
}

// rcBrowser reward
.input-container {
    margin: 0;
    position: relative;
}

.input-inner {
    position: relative;
}

.input-header {
    display: block;
    position: absolute;
    bottom: 50%;
    left: 0;
    transform: translate3d(0, 50%, 0);
    height: 40px;
    line-height: 40px;
    margin-left: 12px;
    padding-right: 9px;
    background-color: $white;
    pointer-events: none;
    font-size: 13px;
    color: $black;
    pointer-events: none;
}

.rcbrowser-input {
    padding: 0 12px;
    height: 42px;
    line-height: 42px;
}

.rcbrowser-input.custom-template-id {
    width: 200px;
}

.rcbrowser-input--number {
    text-align: right;
}

// rcBrowser selectbox
.rcbrowser-select {
    width: 100%;
    box-shadow: inset 0 0 0 1px $light-gray;
    box-shadow: 0 0 5px rgba(0,0,0,.2);
    padding: 0 50px 0 12px;
    height: 42px;
    line-height: 42px;
    border-radius: 4px;
    background-color: $white;
    font-size: 13px;
    color: $dark;
    font-family: inherit;
    cursor: pointer;
}

.select-container {
    margin: 0;
    position: relative;
}

.select-container--answer {
    margin: 0 0 15px;
}

.select-container--question-category {
    width: calc(100% - 15px);
    float: left;
}

.select-container--question-category .rcbrowser-select:focus option:first-of-type,
.select-container--question-arbitrator .rcbrowser-select:focus option:first-of-type {
    display: none;
}

.select-container--question-type {
    width: calc(100% - 15px);
}

.select-container--question-arbitrator {
    width: calc(100% - 15px);
}

.select-container--arbitrator {
    margin: 0 0 15px;
}

.select-container--has-quick-help .rcbrowser-select {
    width: calc(100% - 30px);
}

.input-container--has-quick-help .rcbrowser-input {
    width: calc(100% - 30px);
}

.select-container::after {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 22px;
    height: 22px;
    margin-top: 10px;
    margin-right: 10px;
    background-size: contain;
    background-image: url(../image/icon-dropdown.svg);
    pointer-events: none;
}

.select-container--has-quick-help::after {
    right: 30px;
}

.input-container--has-quick-help::after {
    right: 30px;
}

div.invalid-switch-container { margin-top:2px; margin-right:2px; text-align:right; padding-bottom: 10px; display:none;}
.has-invalid-option div.invalid-switch-container { display:block; }

.input-container a.valid-text-link { font-size:12px; }
.input-container a.invalid-text-link { font-size:12px; }

.input-container .valid-text-link { display:none; }
.input-container .invalid-text-link { display:block; }
.input-container.invalid-selected .valid-text-link { display:block; }
.input-container.invalid-selected .invalid-text-link { display:none; }


div.too-soon-switch-container { margin-top:2px; margin-right:2px; text-align:right; padding-bottom: 10px; display:none;}
.has-too-soon-option div.too-soon-switch-container { display:block; }

.input-container a.not-too-soon-text-link { font-size:12px; }
.input-container a.too-soon-text-link { font-size:12px; }

.input-container .not-too-soon-text-link { display:none; }
.input-container .too-soon-text-link { display:block; }
.input-container.too-soon-selected .not-too-soon-text-link { display:block; }
.input-container.too-soon-selected .too-soon-text-link { display:none; }

.quick-help {
    position: absolute;
    top: 11px;
    right: 0;
}

.quick-help-button {
    display: block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    border-radius: 50%;
    background-color: $ultralight-gray;
    color: $light-gray;
    font-size: 13px;
    font-weight: 500;
    text-align: center;
    position: absolute;
    top: 4px;
    right: 0;
    cursor: pointer;
    z-index: 1;
}

.quick-help-body {
    @include tooltip;
}

.quick-help-body::after {
    @include tooltip_after;
}

.quick-help:hover .quick-help-body {
    display: block;
    animation-duration: .3s;
    transition-timing-function: $ease-in-out-expo;
    animation-fill-mode: both;
    animation-name: bounce;
}

#post-a-question-window {
    min-height:660px;
}

#post-a-question-window .cols-2:nth-child(1) .quick-help-body {
    left: -375px;
}

#post-a-question-window .cols-2:nth-child(2) .quick-help-body {
    left: -380px;
}

#post-a-question-window .quick-help-body::after {
    left: 363px;
}

select[name="question-category"],
select[name="arbitrator"] {
    padding: 0 12px;
    height: 42px;
    line-height: 42px;
    color: $light-gray;
}

select[name="arbitrator"] > option {
    color: $dark;
}

select[name="question-category"] > option {
    color: $dark;
}

select[name="question-category"].selected,
select[name="arbitrator"].selected {    
    color: $dark;
}

.select-answer {
    font-size: inherit;
}


// rcBrowser checkbox
label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 9px 0 0;
    padding: 9px;
    font-size: inherit;
    background-color: lighten($rcBrowserDarkBg, 5%);
    border-radius: 2px;
    cursor: pointer;
}

input[type=checkbox] {
    display: block;
    flex-basis: 14px;
    width: 14px;
    height: 14px;
    margin-right: 6px;
    background-image: url(../image/icon-checkbox--blank.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid gray;
}

input[type=checkbox]:checked {
    background-image: url(../image/icon-checkbox--checked.svg);
}

label > span {
    display: block;
    flex: 1;
}

// rcBrowser submit
.submit-container {
    margin: 40px 0 20px;
}

.rcbrowser-submit {
    @include button_blue;
    margin: 0 10px;
}

// rcBrowser--qa-detail submit
.rcbrowser--qa-detail .submit-container {
    float: none;
    text-align: center;
}

.rcbrowser--qa-detail .reopen-container {
    float: none;
    text-align: center;
    margin-bottom:20px;
    padding-bottom:20px;
    display: none;
    border-bottom: 1px solid #d9dde0;
}

.rcbrowser--qa-detail.reopenable .reopen-container {
    display: block;
}

.rcbrowser--qa-detail .reopened-container {
    float: none;
    text-align: center;
    margin-bottom:20px;
    padding-bottom:20px;
    display: none;
    border-bottom: 1px solid #d9dde0;
}

.rcbrowser--qa-detail .reopened-container {
    text-align: center;
    display:none;
}

.rcbrowser--qa-detail.reopened .reopened-container {
    display: block;
}

.rcbrowser--qa-detail .reopener-container {
    float: none;
    text-align: center;
    margin-bottom:20px;
    padding-bottom:20px;
    display: none;
    border-bottom: 1px solid #d9dde0;
}

.rcbrowser--qa-detail .reopener-container {
    text-align: center;
    display:none;
}

.rcbrowser--qa-detail.reopener .reopener-container {
    display: block;
}

.reopening-display {
    display:none;
}

.reopening .reopening-display {
    display: block;
}

.reopening .reopen-display {
    display:none;
}

a.reopened-question-link {
    color: $blue;
}

a.reopener-question-link {
    color: $blue;
}

.rcbrowser--qa-detail .rcbrowser-submit {
    width: 350px;
    display: inline-block;
}

// rcBrowser Your Q&A
.notifications {
    margin: 0 0 15px;
    border-top: 1px solid $border-main;
}

.notifications-show-hide {
    margin: 0 0 15px;
}

.notifications-item,
.no-notifications-item {
    width: 100%;
    background-color: $rcBrowserDarkBg;
    padding: 10px 0;
    border-bottom: 1px solid $border-main;
}

.notifications-item {
    cursor: pointer;
}

.notifications-item-inner,
.no-notifications-item-inner {
    padding: 9px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.notification-badge {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 9px;
}

.notification-badge--positive {
    background-color: $green;
}

.notification-badge--negative {
    background-color: $red;
}

.no-notifications-item-body {
    margin: 0 auto;
}

.notifications-item-body > p,
.no-notifications-item-body > p {
    margin: -0.1em 0 0;
    line-height: 1.4;
    font-size: 13px;
    color: $rcBrowserBodyText;
}

.notifications-item-body > span {
    font-size: 12px;
    color: $rcBrowserSubText;
}

.see-all-notifications, .hide-lower-notifications {
    display: block;
    float: right;
    font-size: 13px;
    color: $rcBrowserSubText;
    margin-top: 5px;
}

// our q&a
.your-qa {
    position: relative;
}

.your-qa__questions {
    width: 50%;
    float: left;
}

.your-qa__answers {
    width: 50%;
    float: right;
}

.your-qa__questions-inner,
.your-qa__answers-inner {
    padding: 0 21px;
}

.your-qa__questions-header,
.your-qa__answers-header {
    border-bottom: solid 1px lighten($rcBrowserBg, 10%);
    padding: 10px;
    font-size: 13px;
    color: $gray;
    background-color: $ultralight-gray;
    font-weight: 500;
    text-align: left;
}

.your-qa__divider {
    display: block;
    width: 1px;
    height: 100%;
    background-color: lighten($rcBrowserBg, 10%);
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -0.5px;
}

.your-qa__questions__item,
.no-your-qa__questions__item {
    padding: 10px 0;
    border-bottom: solid 1px $border-main;
}

.your-qa__questions__item-date {
    margin: 0;
    font-size: 12px;
    color: $rcBrowserSubText;
}

.your-qa__questions__item-title,
.no-your-qa__questions__item-title {
    display: block;
    margin: 6px 0;
    font-size: 16px;
    padding: 10px 0;
    text-decoration: none;
    color: $dark;
    font-family: $font-family-serif;
}


.no-your-qa__questions__item-title {
    color: $gray;
    background-color: $ultralight-gray;
}

.no-your-qa__questions__item-title {
    text-align: center;
}

.your-qa__questions__item-title {
    cursor: pointer;
}

.your-qa__questions__item-status {
    margin: 15px 0 6px;
    font-size: 12px;
    color: $rcBrowserBodyText;
}

.your-qa__questions__item-status--resolved::before {
    display: inline-block;
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 2px;
    background-color: $green;
}

.your-qa__questions__item-body--latest,
.your-qa__questions__item-body--user {
    padding: 9px;
    font-size: inherit;
    border-radius: 3px;
    color: $rcBrowserBodyText;
    background-color: $rcBrowserDarkBg;
}

.your-qa__questions__item-body--latest,
.your-qa__questions__item-body--user {
    color: $white;
    background-color: $green;
    font-family: $font-family-serif;
}

div.your-qa-header {
    min-height:88px;
}

// rcBrowser placeholder
/* Google Chrome, Safari, Opera 15+, Android, iOS */
textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder {
    color: $light-gray;
}
/* Firefox 18- */
textarea:-moz-placeholder,
input:-moz-placeholder {
    color: $light-gray;
}
/* Firefox 19+ */
textarea::-moz-placeholder,
input::-moz-placeholder {
    color: $light-gray;
}
/* IE 10+ */
textarea:-ms-input-placeholder,
input:-ms-input-placeholder {
    color: $light-gray;
}

textarea:placeholder-shown,
input:placeholder-shown {
    color: $light-gray;
}

#opening-ts-datepicker::placeholder {
    color: $light-gray;
    font-size: 13px;
}

// error
.error-container {
    font-size: 13px;
    color: #ff0000;
    margin-top: 6px;
    padding: 4px 6px 6px;
    line-height: 1.3;
    border-radius: 3px;
    display: none;
}

.error-container--select {
    margin: 0 0 15px;
}

.is-error .error-container {
    display: block;
    animation-duration: 200ms;
    transition-timing-function: $ease-in-out-expo;
    animation-fill-mode: both;
    animation-name: bounce;
}

// option edit
.edit-option-container {
    display: none;
    width: calc(100% - 15px);
}

.edit-option {
    margin: 0;
    padding: 12px 0;
}

.edit-option input:placeholder-shown {
    font-size: 14px !important;
}
.edit-option input {
   margin-bottom: 12px;
}

.edit-option-container.is-open .edit-option {
    opacity: 1;
}

.edit-option-header {
    font-size: 13px;
    color: $gray;
}

.add-option-button {
    width: 100%;
    height: 42px;
    line-height: 42px;
    border-radius: 3px;
    text-align: center;
    box-sizing: border-box;
    background-color: $blue;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    font-size: 14px;
}

// rcBrowser form footer
.rcbrowser-form-footer {
    display: block;
    width: 100%;
    text-align: right;
    font-size: 12px;
    color: $rcBrowserSubText;
    margin: 15px 0 0;
}

// toggle container
.toggle-container {
    overflow: hidden;
    height: 0;
    transition: all 400ms;
}

.toggle-inner {
    opacity: .25;
    transition: all 200ms ease 400ms;
}

.toggle-container.is-open .toggle-inner {
    opacity: 1;
}

.is-bounce {
    animation-duration: .3s;
    transition-timing-function: $ease-in-out-expo;
    animation-fill-mode: both;
    animation-name: bounce;
}

// tooltip
.tooltip {
    position: absolute;
    top: 69px;
    right: 16px;
    z-index: 999;
    background-color: $orange;
    color: $white;
    padding: 12px;
    border-radius: 3px;
    font-size: 15px;
    font-weight: 600;
    box-shadow: 0 2px 10px 0px rgba(0, 0, 0, 0.2);
    opacity: 0;
    visibility: hidden;
    transition: all 300ms;
}

.tooltip.is-visible {
    opacity: 1;
    visibility: visible;
}

.tooltip::before {
    display: block;
    content: "";
    border-top: solid 6px transparent;
    border-bottom: solid 6px $orange;
    border-left: solid 6px transparent;
    border-right: solid 6px transparent;
    position: absolute;
    top: -12px;
    right: 12px;
}

.continue-read-only-message {
    font-size: 14px;
}

// fake metamask
#fake-metamask {
    width: 300px;
    height: 300px;
    position: fixed;
    top: 10%;
    left: 10%;
    z-index: 999999999;
    background-color: $white;
    box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.5);
    display: none;
}

#fake-metamask.is-open {
    display: block;
}

#fake-metamask > span {
    position: absolute;
    bottom: 50%;
    right: 50%;
    transform: translate3d(50%, 50%, 0);
    color: inherit;
    font-size: 24px;
    font-weight: bold;
}

.template-item {
    display:none;
}

.display-top-only .notifications-item.populated-item {display: none}
.display-top-only .notifications-item.populated-item:nth-child(1) {display: block !important}
.display-top-only .notifications-item.populated-item:nth-child(2) {display: block !important}
.display-top-only .notifications-item.populated-item:nth-child(3) {display: block !important}

// Links
.display-all .see-all-notifications {display:none}
.display-top-only .hide-lower-notifications {display:none}

div.notifications-template-container {display:none}

option.exceptional-option {font-style: italic}
option.invalid-select {font-style: italic}
option.too-soon-select {font-style: italic}

div.questions__item__footer span.question-bounty-container { /* float:right  */ 
    margin-left: 20px;}


.answer-form-container{display:none}
div.rcbrowser--qa-detail.question-state-open .answer-form-container{display:block !important}

.arbitration-button{display:none}
div.rcbrowser--qa-detail.question-state-open .arbitration-button{display:block !important}

.answer-deadline-container{display:none}
div.rcbrowser--qa-detail.question-state-open.has-answer .answer-deadline-container{display:block !important; float: left;}
div.rcbrowser--qa-detail.question-state-open.has-answer.all-entries-unrevealed .answer-deadline-container{display:none !important;}

.apply-for-arbitration-container { display: none}
div.rcbrowser--qa-detail.question-state-open .apply-for-arbitration-container { display: block !important}

.pending-arbitration-container { display:none }
div.rcbrowser--qa-detail.question-state-pending-arbitration .pending-arbitration-container { display: block !important}

.reward-value-container { display:none}
div.rcbrowser--qa-detail.question-state-open .reward-value-container { display:inline-block !important}

.add-reward-button { display:none}
div.rcbrowser--qa-detail.question-state-open .add-reward-button.is-open { display:inline-block !important}

.resolved-at-container { display:none }
div.rcbrowser--qa-detail.question-state-finalized .resolved-at-container { display:inline-block !important}

.questions__item__answer { /* display:none */ }
.has-answer .questions__item__answer { display:block !important}

.current-answer-label-final { display: none }
div.rcbrowser--qa-detail.question-state-finalized .current-answer-label-final { display: inline-block !important }

.current-answer-label-current { display: inline-block }
div.rcbrowser--qa-detail.question-state-finalized .current-answer-label-current{ display: none !important }

div.rcbrowser--qa-detail .current-answer-container { display:none }
div.rcbrowser--qa-detail.has-answer .current-answer-container { display: block !important }

div.rcbrowser--qa-detail .unconfirmed-answer-container { display:none }
div.rcbrowser--qa-detail.has-unconfirmed-answer .unconfirmed-answer-container { display: block !important }
div.rcbrowser--qa-detail.question-state-finalized.has-unconfirmed-answer .unconfirmed-answer-container { display: none!important }

div.rcbrowser--qa-detail .answered-history-container { display:none }
div.rcbrowser--qa-detail.has-history .answered-history-container{ display: block !important }

div.rcbrowser--qa-detail .answer-claim-button { display: none}

div.rcbrowser--qa-detail .bond-value-container { display:none }
div.rcbrowser--qa-detail.has-answer .bond-value-container { display:block !important }

.answer-claim-button {display:none}

.answer-debit-info,.answer-credit-info {
    font-size: 13px;
    color: $blue;
    margin-top: 6px;
    padding: 4px 6px 6px;
    line-height: 1.3;
    border-radius: 3px;
    display: none;
    font-style: italic;
}

div.rcbrowser--qa-detail .answer-debit-info{ display:none }
div.rcbrowser--qa-detail .answer-credit-info{ display:none }
div.rcbrowser--qa-detail.has-your-answer .answer-credit-info { display:block !important}
div.rcbrowser--qa-detail.has-someone-elses-answer .answer-debit-info { display:block !important}

a.more-link{ font-style: italic !important}
a.less-link{ font-style: italic !important}

#filter-header span { visibility: hidden }
body.is-page-loaded #filter-header span { visibility: visible !important }

div.questions__item .arbitration-pending-label{ display: none }
div.questions__item.arbitration-pending .arbitration-pending-label{ display:block !important}

div.questions__item.arbitration-pending .closing-time-label { display:none }
div.questions__item.arbitration-pending .created-time-label { display:none }

div.questions__item .closing-time-label .resolved{ display:none }
#questions-resolved div.questions__item .closing-time-label .unresolved{ display:none }
#questions-resolved div.questions__item .closing-time-label .resolved{ display:inline !important}

div.questions__item.no-answers .closing-time-label { display:none  }
div.questions__item.has-answers .created-time-label { display:none  }

div.rcbrowser--qa-detail span.answer-prompt-text.has-answer-only { display:none  }
div.rcbrowser--qa-detail span.answer-prompt-text.no-answer-only { display:inline }
div.rcbrowser--qa-detail.has-answer span.answer-prompt-text.has-answer-only { display:inline !important }
div.rcbrowser--qa-detail.has-answer span.answer-prompt-text.no-answer-only { display:none !important }

div.error-bar { display:none }

body.error div.error-bar {
    /* container */
    padding: 40px;
    background-color: #0a3c6f;
    color: #ffffff;
    font-size: 18px;
    position: static;
    display: block !important;
    text-align: center;
    z-index: 201;
    position: absolute;
    top: 25%;
    left: 50%;
    width: 400px;
    border-radius: 6px;
    box-shadow: 0 0 10px rgba(0,0,0,.2);
    margin-left: -200px;
    margin-top: -60px;
}

div.error-bar .metamask-icon {
    /* image */
    width: 88px;
    margin-left: 24px;
}

div.error-label.request-arbitration-without-answer-error { display:none}
body.error-request-arbitration-without-answer-error div.error-bar div.error-label.request-arbitration-without-answer-error{ display:inline !important}

span.error-label.invalid-network{ display:none}
body.error-invalid-network div.error-bar span.error-label.invalid-network{ display:inline !important}

span.error-label.invalid-network-for-token{ display:none}
body.error-invalid-network-for-token div.error-bar span.error-label.invalid-network-for-token{ display:inline !important}

span.error-label.no-detected-network{ display:none}
body.error-no-detected-network div.error-bar span.error-label.no-detected-network{ display:inline !important}

span.error-label.not-specified-network{ display:none}
body.error-not-specified-network div.error-bar span.error-label.not-specified-network{ display:inline !important}

span.error-label.no-metamask-accounts { display:none}
body.error-no-metamask-accounts div.error-bar span.error-label.no-metamask-accounts { display:inline !important}

span.error-label.no-metamask-plugin { display:none}
body.error-no-metamask-plugin div.error-bar span.error-label.no-metamask-plugin { display:inline !important}

div.error-label.no-metamask-plugin { display:none}
body.error-no-metamask-plugin div.error-bar div.error-label.no-metamask-plugin { display:inline !important}

div.error-label.no-metamask-plugin-image { display:none}
body.error-no-metamask-plugin div.error-bar div.error-label.no-metamask-plugin-image { margin-right:8px; margin-top:-5px; float:right; display:inline !important}

span.error-label.contract-not-found { display:none }
body.error-contract-not-found div.error-bar span.error-label.contract-not-found { display:inline !important}

#footer-notification-bar {
    min-height:80px;
    width: 100%;
    padding: 24px 0;
    background-color: $dark-blue-gray;
    color: $white;
    font-size: 16px;
    text-align: center;
    position: fixed;
    left: 0;
    bottom: 0;
    height: 20px;
    z-index: 5;
}

#got-it-button {
    padding: 8px 16px;
    background-color: $blue;
    color: $white;
    text-decoration: none;
    margin-left: 24px;
    border-radius: 3px;
}

.question-setting-info {
    position: absolute;
    top: 10px;
    right: 0;
}

.rcbrowser--qa-detail .question-setting-warning {
    position: absolute;
    top: 10px;
    left: 0;
}

.rcbrowser--qa-detail.unconfirmed-transaction .question-setting-warning {
    top: 82px !important;
}

.questions__item .question-setting-warning {
    position: absolute;
    top: -10px;
    left: 0;
    width: 24px;
}

.question-setting-info .balloon {
    @include tooltip;
    right: 0;
    bottom: initial;
}

.question-setting-warning .balloon {
    @include tooltip;
}

.balloon::after {
    @include tooltip_after;
}

.rcbrowser-main .question-setting-warning .balloon {
    bottom: initial !important;
    top: 27px;
    text-align: left !important;
}
.question-setting-info .balloon::after ,
.rcbrowser-main .question-setting-warning .balloon::after {
    top: -12px;
    bottom: initial !important;
    border-bottom: solid 6px #3a3c40;
    border-top: solid 6px transparent;
}

.question-setting-info .balloon {
    left: -380px !important;
    text-align: left !important;
    right: initial;
}

.question-setting-info .balloon::after {
    right: 21px;
    left: initial;
}

.material-icons.gray { color: #41464c; }
.material-icons.purple { color: $gray; font-size: 20px;}

.question-setting-info:hover .balloon,
.question-setting-warning:hover .balloon {
    display: block;
    animation-duration: .3s;
    transition-timing-function: $ease-in-out-expo;
    animation-fill-mode: both;
    animation-name: bounce;
}

div.rcbrowser--qa-detail .rcbrowser-warning-header { display:none; }
div.rcbrowser--qa-detail.has-warnings .rcbrowser-warning-header{ display:block !important; }

div.rcbrowser--qa-detail .rcbrowser-warning-header-failed-box { display:none}
div.rcbrowser--qa-detail.failed-transaction .rcbrowser-warning-header-failed-box { display:block !important}

div.rcbrowser--qa-detail.failed-transaction .question-setting-warning { display:none !important }

div.rcbrowser--qa-detail.unconfirmed-transaction div.rcbrowser-main { opacity: 0.6 }
div.rcbrowser--qa-detail.unconfirmed-transaction .question-setting-warning { display:none !important }

div.rcbrowser--qa-detail .rcbrowser-warning-header-failed-box {
    margin-top: 6px;
    padding: 4px 6px 6px;
    line-height: 1.3;
    border-radius: 0px;
    background-color: $dark-gray;
    color: #ffffff; // $RC-white
    font-size: 14px;
    position: static;
    text-align: center;
}

div.rcbrowser--qa-detail .rcbrowser-warning-header-failed-box { display:none}
div.rcbrowser--qa-detail.failed-transaction .rcbrowser-warning-header-failed-box { display:block !important}

div.rcbrowser--qa-detail .rcbrowser-warning-header-failed-box {
    margin-top: 6px;
    padding: 4px 6px 6px;
    line-height: 1.3;
    border-radius: 3px;
    background-color: $red; // $RCS-dark-purple
    color: #ffffff; // $RC-white
    font-size: 14px;
    position: static;
}

div.rcbrowser--qa-detail .rcbrowser-warning-header-failed-box div.pending-txid { float:right }

div.question-setting-info .setting-info-content-hash { font-size: 10px; }
div.question-setting-info .setting-info-question-id { font-size: 10px; }
div.question-setting-info .setting-info-contract-address { font-size: 10px; }

input.arbitrator-other {
    font-size:14px;
    display:none;
    margin: 12px 0;
}

div.arbitrator-tos {
    display:none;
    font-size:12px;
    padding:8px;
}

div#ui-datepicker-div {
    box-shadow: 0 0 20px rgba(0,0,0,.2) !important;
    padding: 0 !important;
}

.ui-widget-header {
    background: $blue !important;
    color: #fff !important;
    border: none !important;
}

table.ui-datepicker-calendar {
    margin: 5px !important;
    width: calc(100% - 10px) !important;
}

.ui-corner-br {
    border-bottom-right-radius: 0 !important;
}
.ui-corner-bl {
    border-bottom-left-radius: 0 !important;
}

.ui-widget-content {
    color: $gray !important;
}

.ui-state-default, 
.ui-widget-content .ui-state-default, 
.ui-widget-header .ui-state-default {
    background: #f7f7f7 !important;
    font-weight: normal !important;
    color: #666 !important;
    border: none !important;
}

.ui-state-hover, 
.ui-widget-content .ui-state-hover, 
.ui-widget-header .ui-state-hover, 
.ui-state-focus, .ui-widget-content .ui-state-focus, 
.ui-widget-header .ui-state-focus {
    border: none !important;
    background: $blue !important;
    font-weight: normal !important;
    color: #fff !important;
}

.ui-state-highlight, 
.ui-widget-content .ui-state-highlight, 
.ui-widget-header .ui-state-highlight {
    border: none !important;;
    background: $blue !important;
    font-weight: bold !important;
    color: #fff !important;
}

.ui-widget-header .ui-icon {
    background-image: url(https://ajax.googleapis.com/ajax/libs/jqueryui/1/themes/flick/images/ui-icons_ffffff_256x240.png) !important;
}

.ui-widget-header ui-state-hover .ui-icon {
    background-image: url(https://ajax.googleapis.com/ajax/libs/jqueryui/1/themes/flick/images/ui-icons_56bbe8_256x240.png) !important;
}

.spinner {
  margin: 80px auto 80px;
  width: 40px;
  height: 40px;
  position: relative;
  color: $blue;
  text-align: center;
}

.spinner p {
    position: absolute;
    width: 380px;
    display: block;
    left: -140px;
    font-size: 14px;
    top: 6px;
    text-align: center;
}

.cube1, .cube2 {
  background-color: $blue;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 0;
  left: 0;
  
  -webkit-animation: sk-cubemove 1.8s infinite ease-in-out;
  animation: sk-cubemove 1.8s infinite ease-in-out;
}

.cube2 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

@-webkit-keyframes sk-cubemove {
  25% { -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5) }
  50% { -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg) }
  75% { -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5) }
  100% { -webkit-transform: rotate(-360deg) }
}

@keyframes sk-cubemove {
  25% { 
    transform: translateX(42px) rotate(-90deg) scale(0.5);
    -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
    } 50% { 
        transform: translateX(42px) translateY(42px) rotate(-179deg);
        -webkit-transform: translateX(42px) translateY(42px) rotate(-179deg);
        } 50.1% { 
            transform: translateX(42px) translateY(42px) rotate(-180deg);
            -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
            } 75% { 
                transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
                -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
                } 100% { 
                    transform: rotate(-360deg);
                    -webkit-transform: rotate(-360deg);
                }
            }

.foreign-proxy-section {display:none; margin-bottom:200px; margin-top:-100px;}
body.foreign-proxy #questions-container {display:none}
body.foreign-proxy #site-introduction__buttons {display:none}
body.foreign-proxy .foreign-proxy-section {display:block}
body.foreign-proxy .top-content-controls {display:none}

.foreign-proxy-network-mismatch-only { display: none}
body.foreign-proxy-network-mismatch .foreign-proxy-network-mismatch-only { display: block}

.foreign-proxy-ready-only { display: none }
body.foreign-proxy-ready .foreign-proxy-ready-only { display: block}

.foreign-proxy-transaction-complete-only {display: none}
body.foreign-proxy-transaction-complete .foreign-proxy-transaction-complete-only {display: block}

.foreign-proxy-transaction-sent-only {display: none}
body.foreign-proxy-transaction-sent .foreign-proxy-transaction-sent-only {display: block}

.proxy-request-arbitration-button {
    margin: 15px 0 0;
    padding: 9px;
    border-radius: 3px;
    font-size: 12px;
    // background-color: $blue;
    @include button_blue;
    text-align: center;
    cursor: pointer;
}

.no-arbitrator-only { display:none; }
.no-arbitrator .no-arbitrator-only { display:block; }

.no-arbitrator-message {
    font-size: 14px;
    font-style: italic;
    background-color: $ultralight-gray;
    padding:8px;
    text-align:center;
    margin-bottom:10px;
}

.failed-arbitrator-only { display:none; }
.failed-arbitrator .failed-arbitrator-only { display:block; }

.failed-arbitrator-message {
    font-size: 14px;
    font-style: italic;
    text-align:center;
    background-color: $ultralight-gray;
    padding:8px;
    margin-bottom:10px;
}

span.add-network-button > a {
    text-decoration:underline;
}
span.add-network-button > a:hover {
    text-decoration:none;
}

div.top-content-controls {
    width:100%;
    height:34px;
}

div.top-content-controls select {
    padding:6px;
}
.filter-edit-container select {
    padding:6px;
}

div.contract-claim-section-template {
    display:none;
}

.version-supports-min-bond-only {
    display:none;
}
.version-supports-min-bond .version-supports-min-bond-only {
    display:block;
}

.arbitrator-tos {
    display:none;
}
.has-arbitrator-tos div.arbitrator-tos {
    display:block;
}

.unconfirmed-answer-item .timeago-container {
    visibility:hidden;
}

.has-time-input input.datetime-input-date {
    width:48%;
}

input.datetime-input-time {
    width:48%;
    display:none;
    float:right;
}
.has-time-input input.datetime-input-time {
    visibility:visible;
    display:block;
}

#chain-list-window .current-chain-text {
    font-weight: bold;
}

.other-chain-list .chain-item.selected-chain {
    display:none !important;
}

.current-chain-display {
    font-size: 18px;
}

body.via-node.connection-error .connection-error-warning.rpc-error {
    background-color: $red;
    color: $white;
    display:block !important; 
    text-align: center;
    font-size:140%;
    padding:8px;
}
body.via-graph.connection-error .connection-error-warning.graph-error {
    background-color: $red;
    color: $white;
    display:block !important; 
    text-align: center;
    font-size:140%;
    padding:8px;
}
body.via-graph.no-graph-endpoint .no-graph-warning {
    background-color: $blue;
    color: $white;
    display:block !important; 
    text-align: center;
    font-size:140%;
    padding:8px;
}

body.network-detection-error .network-detection-error-warning {
    background-color: $red;
    color: $white;
    display:block !important; 
    text-align: center;
    font-size:140%;
    padding:8px;
}

input.filter-input {
    box-shadow: inset 0 0 0 1px $light-gray;
    background-color: $white;
    color: $black;
    border-radius: 3px;
    padding: 0 12px;
    height: 42px;
    line-height: 42px;
    margin: 2px;
    margin-bottom: 6px;
    @include box-shadow;
}

.input.filter-input:focus {
    box-shadow: inset 0 0 0 1px $blue;
}


label.filter-label {
    display:table-cell; 
    font-size:14px;
    color: $gray;
    width:100px;
    height: 42px;
    line-height: 42px;
}

div.filter-display{
    text-align:left;
}

div.filter-edit-container {
    display:none;
    padding:9px;
    background-color: $white;
    margin-bottom:24px;
}

div.filter-edit-container input.filter-input{
}

body.filter-edit-on div.filter-edit-container {
    display:block;
}

body.filter-edit-on div.filter-display-container {
    display:none;
}

a.filter-edit-toggle{
    font-size:14px;
    color: $gray;
}
a.filter-icon:hover {
    text-decoration:none;
}
div.filter-display-container {
    width:100%;
}

div.filter-display-container > div{
    color: $gray;
    vertical-align:middle;
}



.filter-apply-button {
    width: 240px;
    height: 42px;
    line-height: 42px;
    font-weight: 500;
    text-align: center;
    color: $white;
    box-shadow: 0 0 8px rgba(0,0,0,.2);
    border-radius: 6px;
    cursor: pointer;
    background: #27b4ee; /* Old browsers */
    background: -moz-linear-gradient(top, #27b4ee 0%, #0aaaec 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #27b4ee 0%,#0aaaec 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #27b4ee 0%,#0aaaec 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#27b4ee', endColorstr='#0aaaec',GradientType=0 ); /* IE6-9 */
    margin:9px;
}

.has-search-filters-only { display:none }
body.has-search-filters .has-search-filters-only { display:block}

.has-filter-arbitrator-only { display: none}
body.has-filter-arbitrator .has-filter-arbitrator-only { display: block}
.has-filter-creator-only { display: none}
body.has-filter-creator .has-filter-creator-only { display: block}
.has-filter-template-id-only { display: none}
body.has-filter-template-id .has-filter-template-id-only { display: block}
.has-filter-contract-only { display: none}
body.has-filter-contract .has-filter-contract-only { display: block}

span.filter-icon {
    width: 20px;
    height: 20px;
    background-size: contain;
    background-image: url(../image/icon-filter.svg);
    background-repeat: no-repeat;
    background-position: center;
    display:inline-block;
    opacity:50%;
}

.network-switch-error-only {display: none};
.network-switch-error .network-switch-error-only {display: block};

.network-switch-error-text {
    font-style: italic;
}

.loading-text-connected {
    display:none;
}
.network-connected .loading-text-unconnected {
    display:none;
}
.network-connected .loading-text-connected {
    display:inline;
}

.no-graph-endpoint-only {
    display: none;
}
.has-graph-endpoint-only {
    display: block;
}
.no-graph-endpoint .no-graph-endpoint-only {
    display: block;
}
.no-graph-endpoint .has-graph-endpoint-only {
    display: none;
}

.comment-toggle-show .show-hide-show {
    display:none;
}
.comment-toggle-hide .show-hide-hide {
    display:none;
}
.comment-toggle-hide .bsky-comments-body {
    display:none;
}
.has-no-replies .bsky-comments-preview {
    display:none;
}
.input-type-weirdness-warning {
    font-size: 13px;
    margin-top: 8px;
}

.custom-template-only {
    visibility: hidden;
}

.custom-template .custom-template-only {
    visibility:visible;
}

